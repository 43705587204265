import React, { useState } from 'react';
import { Segment, Icon, Header, Modal, Button, Image } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { HtmlEditor } from 'devextreme-react/html-editor';
import { ErrorSegment, ReportForm, VideoPlayer, TextEditor, SpreadsheetEditor } from '../../..';
import { Consts, Styles } from '../../../../res';
import FreeModal from '../FreeModal';
import TBSAttachmentModal from '../TBSAttachmentModal';
import iconImage from '../../../../res/images/logo.svg';
import { replaceFontFamily } from '../../../../helpers/general';
import TbsPdfReader from '../TbsPdfReader';

const { noExtraSpaces, f_14_700_text } = Styles;

const QuestionPage = (props) => {
    const { Q, onAnswerQuestion, loading, error, isMockExam, isViewOnly, onFlagQuestion, onFavQuestion, user } = props;
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === "ar";
    const [showReportIssue, setShowReportIssue] = useState(false);
    const [showExplanation, setShowExplanation] = useState(false);
    const [showAttachmentVideo, setShowAttachmentVideo] = useState(null);
    const [freeModals, setFreeModals] = useState({});
    const [scratchPad, setScratchPad] = useState('');

    const handleOpenFreeModal = (key) => {
        setFreeModals(prev => ({ ...prev, [key]: 10 + Math.max(...Object.values(prev), 0) }));
    };

    if (loading || !Q) return <Segment basic style={{ height: '40vh', ...noExtraSpaces }} loading={loading} />;
    if (error) return <ErrorSegment />;

    const QuestionContent = JSON.parse(Q?.contentJson);
    const StudentAnswers = JSON.parse(Q?.answerJson || '{}');

    const onUserAnswerFile = (file, answer) => {
        const res = { ...StudentAnswers, [file.__KEY__]: answer };
        onAnswerQuestion({ ...Q, answerJson: JSON.stringify(res) });
    };

    return (
        <div style={{ position: 'relative', backgroundColor: "#dcdddd", display: 'flex', flexDirection: 'column', flexGrow: 1, height: '100%', }}>

            <div style={{ display: 'flex', padding: 5, justifyContent: 'space-between', flexDirection: 'row', backgroundColor: '#f2f2f2', borderBottom: 'solid black 1px' }}>
                <div>
                    <Button icon='pencil' content={t('ScratchPad')} onClick={() => handleOpenFreeModal('SCRATCHPAD')} />
                </div>
                <div>
                    {Object.keys(freeModals).length > 0 && <Button icon='window close outline' content={t('CloseAll')} onClick={() => setFreeModals({})} />}
                    <Button icon={Q.isFlagged ? "flag" : "flag outline"} content={t('Flag')} onClick={() => onFlagQuestion(Q)} />
                    <Button icon={Q.isFav ? "star" : "star outline"} content={t('Fav')} onClick={() => onFavQuestion(Q)} />
                </div>
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', gap: 5, flexGrow: 1 }}>
                <div style={{ width: '25%', display: 'flex', flexDirection: 'column', gap: 10, backgroundColor: 'white', padding: 10 }}>
                    <div>
                        <Header>{t('Exhibits')}</Header>
                        {
                            Q.attachments?.map((d, i) => <div key={i} onClick={() => handleOpenFreeModal(d.docUrl)} style={{ cursor: "pointer", display: d.type === "CORRECT_ANSWER" ? 'none' : 'flex', flexDirection: 'row', alignItems: 'center', gap: 5, padding: 10, border: 'solid #ccc 1px', marginBottom: 5, backgroundColor: '#f2f2f2' }}>
                                <div><Icon link color='black' name="book" /></div>
                                <div><Header style={f_14_700_text}>{isArabic ? d.nameAr : d.nameEn}</Header></div>
                            </div>)
                        }
                    </div>

                    {((!isMockExam && Object.keys(StudentAnswers).length) || isViewOnly) && <div>
                        <Header>{t('CorrectAnswer')}</Header>
                        {
                            Q.attachments?.map((d, i) => <div key={i} onClick={() => handleOpenFreeModal(d.docUrl)} style={{ cursor: "pointer", display: d.type === "CORRECT_ANSWER" ? 'flex' : 'none', flexDirection: 'row', alignItems: 'center', gap: 5, padding: 10, border: 'solid #ccc 1px', marginBottom: 5, backgroundColor: '#f2f2f2' }}>
                                <div><Icon link color='black' name="book" /></div>
                                <div><Header style={f_14_700_text}>{isArabic ? d.nameAr : d.nameEn}</Header></div>
                            </div>)
                        }
                    </div>}

                    <div>
                        <Header>{t('Requirements')}</Header>
                        {
                            QuestionContent?.requirements.map((r, i) => <div key={i} onClick={() => handleOpenFreeModal(r.__KEY__)} style={{ cursor: "pointer", display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', gap: 5, padding: 10, border: 'solid #ccc 1px', marginBottom: 5, backgroundColor: '#f2f2f2' }}>
                                <div style={{ display: "flex", alignItems: 'center', flexDirection: "row", gap: 5 }}>
                                    <div><Icon link color='black' name="target" /></div>
                                    <div><Header style={f_14_700_text}>{`${r.title} (${r.mark} ${t('Marks')})`}</Header></div>
                                </div>

                                {(isViewOnly || !isMockExam) ? <div style={{ display: "flex", alignItems: 'center', flexDirection: "row", gap: 2 }}>
                                    {r?.attachments?.map((at, indx) => <Icon link
                                        key={indx}
                                        name={at.type === 'VIDEO' ? "video" : 'file alternate'}
                                        size='small'
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            at.type === 'FILE' && handleOpenFreeModal(at.docUrl);
                                            at.type === 'VIDEO' && setShowAttachmentVideo(at.docUrl);
                                        }}
                                    />)}
                                </div> : null}
                            </div>)
                        }
                    </div>

                    <div>
                        <Header>{t('ResponseOptions')}</Header>
                        {
                            QuestionContent.files.map((f, index) => <div onClick={() => handleOpenFreeModal(f.__KEY__)} key={index} style={{ cursor: "pointer", display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 5, padding: 10, border: 'solid #ccc 1px', marginBottom: 5, backgroundColor: '#f2f2f2' }}>
                                <div><Icon link color='black' name={f.fileType === 'WORD' ? "file text outline" : "table"} /></div>
                                <div><Header style={f_14_700_text}>{f.fileType === "WORD" ? t('WordProcessor') : t('Spreadsheet')}</Header></div>
                            </div>)
                        }
                    </div>
                </div>

                <div style={{ position: 'relative', width: '75%', backgroundColor: 'white', padding: 10 }}>
                    <HtmlEditor value={replaceFontFamily(isArabic ? QuestionContent?.textAr?.trim() : QuestionContent?.textEn?.trim())} readOnly style={{ border: 'none', boxShadow: 'none' }} className='resizo' />

                    <div className='studentExamWatermark' style={{ top: '40%', left: '40%' }}>
                        <Image loading="lazy" size='medium' src={iconImage} />
                    </div>

                    <Header
                        as='h1'
                        content={`${user.firstName} ${user.lastName} [${user.email}] ${user.contactNumber}`}
                        className='studentExamWatermark'
                    />

                </div>

            </div>


            <Modal
                onClose={() => setShowReportIssue(false)}
                open={showReportIssue}
                content={<ReportForm reportOn={Consts.REPORTS_TYPES.QUESTION} reportLink={Q} id={Q.questionId} showModal={setShowReportIssue} />}
                closeIcon
                header={t('ReportProblem')}
                size='small'
                centered
                closeOnDimmerClick={false}
            />

            <Modal
                onClose={() => setShowExplanation(false)}
                open={showExplanation}
                content={Q && Q.correctAnswer && Q.correctAnswer.explanations && Q.correctAnswer.explanations.videos.length ? <VideoPlayer kind={Consts.VIDEOS_KINDS.EXPLANATION} videoDetails={{ ...Q.correctAnswer.explanations.videos[0], ...Q }} onClose={() => setShowExplanation(false)} /> : <ErrorSegment text={t('ComingSoon')} icon='wait' />}
                basic={Q && Q.correctAnswer && Q.correctAnswer.explanations && Q.correctAnswer.explanations.videos.length ? true : false}
                size='large'
                centered
                closeIcon={!(Q && Q.correctAnswer && Q.correctAnswer.explanations && Q.correctAnswer.explanations.videos.length)}
                closeOnDimmerClick={false}
            />

            <Modal
                onClose={() => setShowAttachmentVideo(null)}
                open={Boolean(showAttachmentVideo)}
                content={Boolean(showAttachmentVideo) && <VideoPlayer kind={Consts.VIDEOS_KINDS.GENERAL} videoDetails={{ url: showAttachmentVideo.urlEn, showNotes: false, showReactions: false, showStats: false }} onClose={() => setShowAttachmentVideo(null)} />}
                size='small'
                centered
                closeIcon
                closeOnDimmerClick={false}
            />

            <FreeModal
                onClose={setFreeModals}
                fileKey={'SCRATCHPAD'}
                title={t('ScratchPad')}
                open={freeModals['SCRATCHPAD']}
                children={<TextEditor data={{ value: scratchPad, setValue: setScratchPad }} />}
            />

            {
                Q.attachments.map((d, i) => <FreeModal
                    key={i}
                    onClose={setFreeModals}
                    fileKey={d.docUrl}
                    title={isArabic ? d.nameAr : d.nameEn}
                    open={freeModals[d.docUrl]}
                    children={d.fsType === "FR_EXCEL" ? <SpreadsheetEditor docUrl={d.docUrl} readOnly /> : <TbsPdfReader docUrl={d.docUrl} /> }
                />)
            }

            {
                QuestionContent.requirements.map((r, i) => <FreeModal
                    onClose={setFreeModals}
                    fileKey={r.__KEY__}
                    title={r?.title?.toUpperCase()}
                    open={freeModals[r.__KEY__]}
                    children={<HtmlEditor value={isArabic ? r?.contentAr : r?.contentEn} readOnly style={{ border: 'none', boxShadow: 'none' }} />}
                    key={i}
                />)
            }

            {
                QuestionContent.requirements
                    .reduce((acc, r) => [...acc, ...r?.attachments?.filter(at => at.type === "FILE") || []], [])
                    .map((at, i) => <TBSAttachmentModal
                        key={i}
                        onClose={setFreeModals}
                        fileKey={at.docUrl}
                        title={t('TheAnswerOfRequirment')}
                        open={freeModals[at.docUrl]}
                        docUrl={at.docUrl}
                    />)
            }

            {
                QuestionContent.files.map((f, i) => <FreeModal
                    onClose={setFreeModals}
                    fileKey={f.__KEY__}
                    title={f.fileType === 'EXCEL' ? t("Spreadsheet") : t("WordProcessor")}
                    open={freeModals[f.__KEY__]}
                    children={<FileAnswer isViewOnly={isViewOnly} file={f} onAnswer={onUserAnswerFile} answer={StudentAnswers[f.__KEY__]} />}
                    key={i}
                />)
            }


        </div>
    );
};


const FileAnswer = ({ answer, onAnswer, file, isViewOnly }) => {
    const { i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';
    const initialAdminValue = isArabic ? file?.fileContentAr : file?.fileContentEn;

    const [value, setValue] = useState(answer || initialAdminValue || "");
    return (

        file.fileType === "EXCEL" ? <SpreadsheetEditor
            data={{ value, setValue }}
            onBlur={() => isViewOnly ? undefined : onAnswer(file, value)}
            readOnly={isViewOnly}
        />
            : <TextEditor
                onBlur={() => isViewOnly ? undefined : onAnswer(file, value)}
                data={{ value, setValue }}
            />

    );
};

export default QuestionPage;