import React, { useState } from 'react';
import request, { BASE_URL } from '../../api';
import SpreadsheetEditor from '../Generals/SpreadsheetEditor';

const SpreadSheetUploader = ({ data, Url }) => {
    const [value, setValue] = useState([{ name: 'Sheet1', celldata: [{ r: 0, c: 0, v: null }] }]);
    const uploadUrl = Url ? `${BASE_URL}${Url}` : `${BASE_URL}/doc/upload`;

    const onUpload = async () => {
        const blob = new Blob([JSON.stringify(value)], {
            type: 'application/json',
        });
        const formData = new FormData();
        formData.append("doc", blob, "fsheet.json");
        const { data: axData } = await request.post(uploadUrl, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        if (data?.setValue)
            data.setValue(axData?.docUrl);
    };

    return (
        <div style={{ height: '40vh' }}>
            <SpreadsheetEditor docUrl={data?.value} data={{ value, setValue }} iconName='upload' onBlur={onUpload} />
        </div>
    );
};



export default SpreadSheetUploader;