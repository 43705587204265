import React, { useEffect, useRef, useState } from 'react';
import { Icon, Segment } from 'semantic-ui-react';
import request from '../../api';
import { useTranslation } from 'react-i18next';
import { Workbook } from "@fortune-sheet/react";
import "@fortune-sheet/react/dist/index.css";

const transformData = (responseData) => {
    return responseData?.map((sheet) => ({
        id: sheet.id,
        name: sheet.name,
        celldata: sheet?.data?.flatMap((row, rIndex) =>
            row?.map((cell, cIndex) => {
                if (cell !== null) {
                    return {
                        r: rIndex,
                        c: cIndex,
                        v: cell,
                    };
                }

                return undefined;
            })
                ?.filter((cell) => cell !== undefined),
        ),
        calcChain: sheet?.calcChain?.map((item) => {
            const relatedCell = sheet.data[item.r][item.c];
            return {
                r: item.r,
                c: item.c,
                id: item.id,
                v: relatedCell !== null ? relatedCell : null,
            };
        }),
    }));
};


const SpreadsheetEditor = ({ docUrl, data, onBlur = () => { }, readOnly, iconName = 'save' }) => {
    const ref = useRef();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [innerData, setInnerData] = useState(data?.value ? transformData(data?.value) : [{ name: 'Sheet1', celldata: [{ r: 0, c: 0, v: null }] }]);

    useEffect(() => {
        if (docUrl) {
            setLoading(true);
            request.get(docUrl)
                .then(({ data: res }) => { setInnerData(transformData(res)); setLoading(false); })
                .catch(() => { });
        }
    }, []);

    if (loading) return <Segment basic loading />;
    return (
        <div id="parentSheet" style={{ width: "100%", height: "100%" }}>
            <Workbook
                ref={ref}
                data={innerData}
                allowEdit={!readOnly}
                column={26}
                row={36}
                defaultColWidth={150}
                customToolbarItems={readOnly ? [] : [{ key: "saveme", tooltip: iconName === "save" ? t("Save") : t("Upload"), onClick: onBlur, icon: <div style={{ marginLeft: 'auto' }}><Icon name={iconName} /></div> }]}
                lang="en"
                onChange={data?.setValue}
            />
        </div>
    );
};

export default SpreadsheetEditor;